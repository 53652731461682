<template>
  <static-fullscreen-card @sendHeight="cardHeight = $event" :title="false" :loaded="loaded">
    <portal to="v-info" class="pl-2"><v-btn small @click="restart()" v-if="result.id">Начать заново </v-btn> </portal>

    <v-card-text :style="` width: 100%; height: ${cardHeight - 38}px; overflow: auto`" class="pa-0" ref="chat_block">
      <Emulator
        v-if="loaded"
        ref="lesson_chat"
        :lesson="result"
        :chat="chat"
        @next="onNext($event)"
        @restart="onRestart($event)"
        @complete="onComplete($event)"
        @goto="onGoto($event)"
      />
    </v-card-text>
  </static-fullscreen-card>
</template>

<script>
import "./../components";
import { getAccess, autoHeightBlock } from "@/components/mixings";
export default {
  mixins: [getAccess, autoHeightBlock],
  components: {
    Emulator: () => import("./lesson"),
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.education.message,
      chat: [],
      cardHeight: 300,
      lesson: null,
      loaded: false,
      types: { 1: "text", 2: "media", 3: "question", 4: "testing" },
      result: {},
    };
  },
  created() {
    this.initData();
    this.$root.title = "Система обучения";
  },
  watch: {
    "$route.params.id"(v) {
      this.initData();
    },
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    api() {
      return this.m.api;
    },
    model() {
      let model = this.getModelList(this.m);
      return model;
    },
    canAdd() {
      return true;
    },
  },
  methods: {
    async restart() {
      this.loaded = false;
      await this.$axios.post("/mechti/education/result", { status: 1, education_chain_id: this.id });
      // if (this.result.id) await this.$axios.post("/mechti/education/result", { id: this.result?.id, status: 2 });
      this.initData();
    },
    async complete() {
      if (this.result.id) {
        if (await this.$axios.post("/mechti/education/result", { id: this.result.id, status: 3 }))
          this.$axios.post("/notify_socket", { name: "completeEducation", id: this.id });
        this.result.status = 3;
      }
    },
    async saveResult(messages) {
      try {
        let res = await this.$axios.post("/mechti/education/result", {
          id: this.result?.id,
          status: 1,
          education_chain_id: this.id,
          data: { messages: this.result.messages },
        });
        let result = res.data.data;
        this.result = { id: result?.id, status: result?.status, messages: result?.data?.messages || [] };
        // this.$refs.lesson_chat.result = [...(this.result?.messages || [])];
        return true;
      } catch (error) {
        this.$root.$emit("show-info", { type: "error", text: "Ошибка сохранения результата" });
        return !true;
      }
    },
    async getMessage(id) {
      this.chat.push(this.messages.find((m) => m.id == id));
      await this.scrollTo(id);
    },
    async onNext({ item, message, next_id }) {
      if (!this.result) this.result = { messages: [] };
      this.result["messages"].push({ message_id: item.id, status: "done", next_id });
      if (await this.saveResult()) this.getMessage(next_id);
    },
    async onGoto({ item, message, next_id }) {
      if (!this.result) this.result = { messages: [] };
      this.result["messages"].push({ message_id: item.id, status: "done", answer: message.answer, next_id });
      if (await this.saveResult()) this.getMessage(next_id);
    },
    async onRestart({ message }) {
      console.log("onRestart", message);
      if (!this.result) this.result = { messages: [] };
      this.result["messages"].push(message);
      if (await this.saveResult()) this.restart();
    },
    async onComplete({ message }) {
      if (!this.result) this.result = { messages: [] };
      this.result["messages"].push(message);
      if (await this.saveResult()) this.complete();
    },
    scrollTo(id) {
      this.$nextTick(() => {
        if (!this.$refs.lesson_chat || !this.$refs.lesson_chat.$refs[`message-${id}`]) return;
        const chatBlock = this.$refs.chat_block;
        const newMessageElement = this.$refs.lesson_chat.$refs[`message-${id}`][0];
        console.log("newMessageElement", newMessageElement);
        if (chatBlock && newMessageElement) {
          const chatBlockHeight = chatBlock.clientHeight;
          const newMessagePosition = newMessageElement.$el.offsetTop;
          const offset = chatBlockHeight;
          chatBlock.scrollTop = newMessagePosition;
        }
      });
    },
    async initData() {
      (this.result = {}), (this.chat = []), (this.messages = []);
      await this.fitchData();
      if (this.result?.messages?.length) {
        this.result.messages.forEach((el, i) => {
          if (i == 0) {
            console.log(el.message_id);
            this.getMessage(el.message_id);
          }
          this.getMessage(el.next_id);
          console.log(el.next_id);
        });
      } else this.chat.push(this.messages.find((m) => (m.id = this.lesson.education_message_id)));
    },
    async fitchData() {
      this.loaded = !true;
      try {
        let l = await this.$axios.get("/mechti/education/chain/" + this.id);
        this.lesson = l.data.data;
        let params = {
          filters: { education_chain_id: this.id },
        };
        let d = await this.$axios.get(this.api, { params });
        this.messages = [...d.data.data];
        let res = await this.$axios.get("/mechti/education/result", {
          params: { filters: { status: [1, 3], education_chain_id: this.id, user_id: this.$root.profile.id }, sort: { key: "id", order: "DESC" }, pager: { limit: 1 } },
        });
        console.log("result finded", res.data.data?.[0] || {});
        let result = res.data.data?.[0] || {};
        this.result = { id: result?.id, status: result?.status, messages: result?.data?.messages || [] };

        this.loaded = true;
      } catch (error) {
        console.error(error);
        this.$root.$emit("show-info", { type: "error", text: "Ошибка загрузки данных" });
      }
    },
  },
};
</script>
